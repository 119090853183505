<template>
  <v-dialog persistent @keydown.esc="close" v-model="bDialogEditInformation" :width="screenWidth + '%'">
    <v-card class="global-container-dialog">
      <v-btn @click="close" icon class="global-btn-close">
        <v-icon> mdi-close </v-icon>
      </v-btn>

      <v-card-title>
        <div class="content-title-edit-information">
          <p class="title-edit-information">Modificar información</p>
        </div>
      </v-card-title>

      <v-card-text>
        <div class="content-img">
          <div v-if="dataImg !== null || this.sImgEdit !== null" class="content-backgorund">
            <v-btn @click="editImg" icon class="button-edit-item">
              <v-icon size="20px"> mdi-pencil </v-icon>
            </v-btn>
            <img v-if="this.sImgEdit !== null" class="img-raw-material" :src="this.sImgEdit" />
            <img v-else class="img-raw-material" :src="maskedFile(dataImg)" />
          </div>
          <div v-else class="content-backgorund">
            <v-btn @click="$refs.inputUpload.click()" class="button-add-img">
              <div>
                <v-icon color="#B2B2B2" size="40px"> mdi-image-plus </v-icon>
                <p class="text-img-add">Arrastra ó da clic</p>
                <p class="text-img-add">para añadir</p>
              </div>
            </v-btn>
          </div>
          <input ref="inputUpload" style="display: none" type="file" size="60" accept=".jpg, .jpeg, .png"
            multiple="false" @change="uploadImg" />
        </div>

        <div class="content-text-fields">
          <v-text-field v-model="sCodeEdit" label="Clave" placeholder="Clave..." class="global-text-field mb-3"
            color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
            persistent-placeholder outlined maxlength="20" @keyup="validateForm()">
            <template slot="label">
              <span>Clave<span class="important-data">*</span></span>
            </template>
          </v-text-field>
          <v-text-field v-model="sNameEdit" label="Nombre" placeholder="Nombre..." class="global-text-field mb-3"
            color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
            persistent-placeholder outlined maxlength="50" @keyup="validateForm()">
            <template slot="label">
              <span>Nombre<span class="important-data">*</span></span>
            </template>
          </v-text-field>
          <v-text-field v-model="sColorKeyEdit" label="Color" placeholder="Color..."
            class="global-text-field cursor-pointer" color="var(--primary-color-border-input)"
            background-color="var(--primary-color-menu)" persistent-placeholder outlined maxlength="9"
            @change="validateForm()" @keyup="validateForm()" @keypress="fieldHexadecimal($event)">
            <template slot="label">
              <span>Color<span class="important-data">*</span></span>
            </template>
            <template slot="append">
              <v-menu v-model="menuPickerColor" bottom min-width="200px" :close-on-content-click="false" offset-x
                rounded="lg">
                <template v-slot:activator="{ on }">
                  <div v-on="on" class="btn-icon-color-dynamic c-pointer">
                    <v-icon :style="{ color: sColorKeyEdit }" class="icon-color-dynamic c-pointer">
                      mdi-checkbox-blank-circle</v-icon>
                  </div>
                  <v-btn icon v-on="on" class="btn-arrow-down-color">
                    <v-icon color="var(--primary-color-text)">mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-card class="content-picker-color-menu pa-2">
                  <div class="mx-auto">
                    <v-color-picker v-model="sColorKeyEdit" hide-inputs dot-size="17" mode="hexa"
                      swatches-max-height="200"></v-color-picker>
                    <div class="content-btns-picker-color">
                      <v-btn @click="menuPickerColor = !menuPickerColor" class="global-btn-neutral">Cancelar</v-btn>
                    </div>
                  </div>
                </v-card>
              </v-menu>
            </template>
          </v-text-field>
        </div>
      </v-card-text>

      <v-card-text>
        <v-divider class="divider-sidebar-global mb-3"></v-divider>

        <div class="content-btns-accions">
          <div class="content-btn-second">
            <v-btn v-if="bEdit" @click="close" class="global-btn-neutral">
              Cancelar
            </v-btn>
            <v-btn v-else @click="discardChange()" class="global-btn-neutral">
              Descartar cambios
            </v-btn>
          </div>

          <v-spacer></v-spacer>
          <div class="content-btn-primary">
            <v-btn class="global-btn-primary" @click="changeRawMaterial()" :disabled="bEdit" :loading="bLoading">
              Guardar cambios
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    bDialogEditInformation: Boolean,
    itemRawMaterial: Object,
  },
  data() {
    return {
      screenWidth: 0,
      bLoading: false,
      bEdit: true,
      dataImg: null,
      sImgEdit: null,
      sCodeEdit: "",
      sNameEdit: "",
      sColorKeyEdit: "FFFFFFFF",
      iBasePrice: 0,
      oRawMaterial: {},
      oImageRawMaterial: {},
      types: ["hex"],
      type: "hex",
      menuPickerColor: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      // size to range
      if (window.innerWidth < 599) {
        this.screenWidth = 100;
      } else if (window.innerWidth > 600 && window.innerWidth < 959) {
        this.screenWidth = 65;
      } else if (window.innerWidth > 960 && window.innerWidth < 1263) {
        this.screenWidth = 45;
      } else if (window.innerWidth > 1264 && window.innerWidth < 1903) {
        this.screenWidth = 35;
      } else if (window.innerWidth > 1904) {
        this.screenWidth = 25;
      }
    },
    uploadImg: function (e) {
      this.dataImg = null;
      this.sImgEdit = null;

      const files = e.target.files;
      this.dataImg = files;
      this.validateForm();
    },
    editImg: function () {
      this.$refs.inputUpload.click();
    },
    removeImg: function () {
      this.dataImg = null;
      this.validateForm();
    },
    changeRawMaterial() {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.bAdminMaterial) {
            const payload = {
              sCode: this.sCodeEdit,
              sMeasurementUnitId: "75f72e59-d1d5-4fbd-803a-d9166c02f66d",
              sName: this.sNameEdit,
              sColorKey: this.sColorKeyEdit.replace("#", ""),
            },
              config = {
                headers: {
                  Authorization: "Bearer " + this.$store.state.sToken,
                },
              };
            DB.put(
              `${URI}/api/sp/v1/raw-materials/${this.$route.params.id}`,
              payload,
              config
            )
              .then((response) => {
                if (this.dataImg !== null) {
                  this.EditImgRawMaterial(
                    this.$route.params.id,
                    response.data.message,
                    response.data.results.sName
                  );
                } else {
                  this.bLoading = false;
                  this.$store.commit("refresher", true);
                  this.close();
                  this.Success(
                    response.data.message,
                    response.data.results.sName
                  );
                }
              })
              .catch((error) => {
                this.bLoading = false;
                this.Error(error.response.data);
              });
          } else {
            this.Error(this.$store.state.oError403);
            this.close();
          }
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    EditImgRawMaterial(id, sMessage, sFullName) {
      let form = new FormData();
      form.append("aImage", this.dataImg[0]);

      const config = {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      };

      DB.patch(`${URI}/api/sp/v1/raw-materials/${id}`, form, config)
        .then((response) => {
          this.bLoading = false;
          this.$store.commit("refresher", true);
          this.close();
          this.Success(sMessage, sFullName);
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },

    maskedFile: function (file) {
      return URL.createObjectURL(file[0]);
    },
    discardChange() {
      this.sCodeEdit = this.oRawMaterial.sCode;
      this.sNameEdit = this.oRawMaterial.sName;
      this.sColorKeyEdit = this.oRawMaterial.sColorKey;
      this.sImgEdit = this.oRawMaterial.oImages.md
        ? this.oRawMaterial.oImages.md
        : null;
      this.bEdit = true;
      this.validateForm();
    },
    close() {
      this.$emit("setDialogEditInformation");
      this.dataImg = null;
      this.sImgEdit = null;
      this.sId = "";
      this.sName = "";
      this.hex = "#FFFFFFFF";
    },
    validateForm() {
      this.bEdit =
        this.sImgEdit === this.oRawMaterial.oImages.md &&
        (this.sCodeEdit.trim() === "" ||
          this.sCodeEdit.trim() === this.oRawMaterial.sCode.trim()) &&
        (this.sNameEdit.trim() === "" ||
          this.sNameEdit.trim() === this.oRawMaterial.sName.trim()) &&
        this.sColorKeyEdit.replace("#", "") === this.oRawMaterial.sColorKey;
    },
    fieldHexadecimal(evt) {
      if (
        !(
          (evt.keyCode >= 48 && evt.keyCode <= 57) ||
          (evt.keyCode >= 65 && evt.keyCode <= 90) ||
          (evt.keyCode >= 97 && evt.keyCode <= 122)
        )
      ) {
        evt.preventDefault();
      }
      // if (
      //   (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
      //   evt.which > 91
      // ) {
      //   evt.preventDefault();
      // }
    },
    changeMontoGlobal(val) {
      this.iBasePrice = parseFloat(val);
    },
  },
  computed: {
    // bDialogEditInformation() {
    //   return this.$store.state.bDialogEditInformation;
    // },
    // itemRawMaterial() {
    //   return this.$store.state.itemRawMaterial;
    // },
    bShowMaterial() {
      return this.$store.state.bShowMaterial;
    },
    bAdminMaterial() {
      return this.$store.state.bAdminMaterial;
    },
  },
  watch: {
    bDialogEditInformation() {
      if (this.bDialogEditInformation) {
        this.oRawMaterial = this.itemRawMaterial;
        this.sImgEdit = this.itemRawMaterial.oImages.md;
        this.sCodeEdit = this.itemRawMaterial.sCode;
        this.sNameEdit = this.itemRawMaterial.sName;
        this.sColorKeyEdit = "#" + this.itemRawMaterial.sColorKey;
        this.bEdit = true;
        // this.validateForm();
      }
    },
    sColorKeyEdit() {
      if (!this.sColorKeyEdit.includes("#")) {
        this.sColorKeyEdit = this.sColorKeyEdit.replace("#", "");
        this.sColorKeyEdit = "#" + this.sColorKeyEdit;
      }
      this.validateForm();
    },
  },
};
</script>
<style scoped>
.btn-icon-color-dynamic {
  border: solid var(--primary-color-text) 1px;
  border-radius: 100%;
  margin-top: -5px;
  margin-bottom: 5px;
  height: 26px;
  width: 26px;
}

.icon-color-dynamic {
  width: 100%;
  height: 100%;
}

.btn-arrow-down-color {
  margin-top: -10px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.content-title-edit-information {
  width: 100%;
}

.content-img {
  margin-bottom: 25px;
  justify-content: center;
  display: flex;
}

.content-image {
  width: 250px;
  max-width: 250px;
  min-width: 250px;
  height: 200px !important;
  position: relative;
  border: 1px solid #70707080;
  border-radius: 20px;
}

.button-delete-item {
  position: absolute;
  right: 60px;
  bottom: 5px;
  min-width: 30px !important;
  height: 30px !important;
  background-color: var(--primary-color-btn-neutral-red) !important;
  padding: 0px !important;
  border-radius: 10px;
}

.button-edit-item {
  position: absolute;
  right: 15px;
  bottom: 5px;
  min-width: 30px !important;
  height: 30px !important;
  background-color: var(--primary-color-btn-primary) !important;
  padding: 0px !important;
  border-radius: 10px;
}

.content-backgorund {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.img-raw-material {
  height: 200px;
  width: 250px;
  object-fit: cover;
  border-radius: 5%;
  border: var(--primary-color-border-text-field) solid 3px;
}

.button-add-img {
  width: 250px;
  max-width: 250px;
  min-width: 250px;
  height: 200px !important;
  background: transparent 0% 0% no-repeat padding-box !important;
  border: 2px dashed var(--primary-color-divider);
  border-radius: 5%;
  opacity: 1;
}

.text-img-add {
  color: var(--primary-color-text) !important;
  font-family: "pop-Semibold";
  font-size: 9px;
  text-transform: initial;
}

.title-edit-information {
  color: var(--primary-color-text) !important;
  font-family: "pop-SemiBold";
  font-size: 25px;
  text-align: center;
  word-break: normal;
}

.content-btns-accions {
  display: flex;
  width: 100% !important;
}

.content-btn-primary {
  width: 100%;
}

.content-btn-second {
  width: 100%;
  margin-right: 15px;
}

.content-btns-picker-color {
  width: 50%;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .text-img-add {
    color: var(--primary-color-text) !important;
    font-family: "pop-Semibold";
    font-size: 6px;
    text-transform: initial;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>